import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import LoadingScreen from '../components/LoadingScreen';

const Login = () => {
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const success = await login(password);
      if (success) {
        navigate('/admin');
      } else {
        alert('Invalid password');
      }
    } catch (error) {
      console.error('Login failed', error);
      alert('Login failed');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#fafafa',
      fontFamily: 'Arial',
    }}>
      <h2 style={{ marginBottom: '20px', textAlign: 'center', color: '#333' }}>Admin Login</h2>
      <form onSubmit={handleSubmit} style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
      }}>
        <label style={{ marginBottom: '10px', fontSize: '14px', fontWeight: '600' }}>
          Password:
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            style={{ 
              marginTop: '5px', 
              padding: '10px', 
              width: '200px', 
              borderRadius: '5px', 
              border: '1px solid #ccc' 
            }} 
          />
        </label>
        <input 
          type="submit" 
          value="Submit" 
          style={{ 
            marginTop: '10px', 
            padding: '10px 20px', 
            color: '#fff', 
            backgroundColor: '#007BFF', 
            borderRadius: '5px', 
            border: 'none', 
            cursor: 'pointer' 
          }} 
        />
      </form>
    </div>
  );
};

export default Login;