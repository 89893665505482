import React, { useState, useEffect } from 'react';
import { Button, Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { FiPlus, FiFilter, FiSearch } from 'react-icons/fi';
import AddProduct from '../components/AddProduct';
import axios from 'axios';
import DragNDrop from '../components/DragNDrop';
import BrandDragNDrop from '../components/BrandDragNDrop';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledAdminContainer = styled.div`
  background-color: #F5F5F5;
  min-height: 100vh;
  padding-top: 120px;
`;

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #E8E8E8;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
`;

const StyledLogo = styled.img`
  height: 60px;
  width: auto;
`;

const StyledButton = styled(Button)`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const StyledSelect = styled(Form.Select)`
  margin-right: 10px;
  text-align: center;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #fff;
`;

const StyledSearchInput = styled(Form.Control)`
  text-align: center;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #ced4da;
`;
const Admin = () => {
    const [showModal, setShowModal] = useState(false);
    const [flowerItems, setFlowerItems] = useState([]);
    const [solventlessItems, setSolventlessItems] = useState({});
    const [concentratesItems, setConcentratesItems] = useState([]);
    const [cartridgesItems, setCartridgesItems] = useState([]);
    const [ediblesItems, setEdiblesItems] = useState([]);
    const [prerollsItems, setPrerollsItems] = useState([]);
    const [solventlessCartItems, setSolventlessCartItems] = useState({});
    const [productToEdit, setProductToEdit] = useState(null);
    const [strain, setStrain] = useState('');
    const [category, setCategory] = useState('');
    const [quantities, setQuantities] = useState([]);
    const [prices, setPrices] = useState([]);
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [onSale, setOnSale] = useState(false);
    const [inStock, setInStock] = useState(false);
    const [brand, setBrand] = useState('');
    const [reno, setReno] = useState(false);
    const [filter, setFilter] = useState('All');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');
    const [brandFilter, setBrandFilter] = useState('All');
    const [stockFilter, setStockFilter] = useState('All');
    const [locationFilter, setLocationFilter] = useState('All');
    const [filteredItems, setFilteredItems] = useState({
      flower: [],
      solventless: {},
      concentrate: [],
      cartridge: [],
      edible: [],
      preroll: [],
      'solventless-cart': {}
    });
    const [solventlessBrandOrder, setSolventlessBrandOrder] = useState([]);
    const [solventlessCartBrandOrder, setSolventlessCartBrandOrder] = useState([]);
    const [orderStructure, setOrderStructure] = useState({
      productOrder: {},
      brandOrder: {}
    });
    const { logout } = useAuth();
    const navigate = useNavigate();
  
    const handleLogout = async () => {
        await logout();
        navigate('/login');
      };
    
      const handleClose = () => {
        setShowModal(false);
        setProductToEdit(null);
      };
    
      const handleShow = () => {
        setShowModal(true);
        setProductToEdit(null);
        setStrain('');
        setCategory('');
        setQuantities([]);
        setPrices([]);
        setDescription('');
        setImage(null);
        setOnSale(false);
        setInStock(false);
        setBrand('');
        setType('');
        setFilter('All');
      };
    
      const editProduct = (item) => {
        setProductToEdit(item);
        setStrain(item.strain);
        setCategory(item.category);
        setDescription(item.description);
        setImage(item.image);
        setOnSale(item.onSale === 'true' || item.onSale === true);
        setInStock(item.inStock === 'true' || item.inStock === true);
        setBrand(item.brand);
        setType(item.type || '');
        setReno(item.reno === 'true' || item.reno === true);
        
        try {
          const prices = typeof item.prices === 'string' ? JSON.parse(item.prices) : item.prices;
          const quantities = prices.map(price => price.quantity);
          setQuantities(quantities);
          prices.forEach(price => handlePriceChange(price.price, price.quantity));
        } catch (error) {
          console.error('Error parsing prices:', error);
          setQuantities([]);
          setPrices([]);
        }
        
        setShowModal(true);
      };
    
      const handlePriceChange = (value, quantity) => {
        setPrices(prevPrices => {
          const newPrices = [...prevPrices];
          const priceIndex = newPrices.findIndex(item => item.quantity === quantity);
          if (priceIndex !== -1) {
            newPrices[priceIndex].price = value;
          } else {
            newPrices.push({ quantity, price: value });
          }
          return newPrices;
        });
      };
    
      const deleteProduct = async (productId) => {
        try {
          const response = await axios.delete(`https://arborconnect-267f83cd8e65.herokuapp.com/delete-product/${productId}`);
          console.log(response.data);
          getProducts(); // Refresh the products after deletion
        } catch (error) {
          console.error(`Error occurred while deleting product: ${error}`);
        }
      };
    
      const getAllBrands = () => {
        const allItems = [
          ...flowerItems,
          ...Object.values(solventlessItems).flat(),
          ...Object.values(solventlessCartItems).flat(),
          ...concentratesItems,
          ...cartridgesItems,
          ...ediblesItems,
          ...prerollsItems
        ];
        const uniqueBrands = [...new Set(allItems.map(item => item.brand))];
        return ['All', ...uniqueBrands.sort()];
      };
    
      const getBrandsByType = (type) => {
        let items;
        switch(type) {
          case 'Flower': items = flowerItems; break;
          case 'Solventless': items = Object.values(solventlessItems).flat(); break;
          case 'Solventless-Cart': items = Object.values(solventlessCartItems).flat(); break;
          case 'Concentrate': items = concentratesItems; break;
          case 'Cartridge': items = cartridgesItems; break;
          case 'Edible': items = ediblesItems; break;
          case 'Preroll': items = prerollsItems; break;
          default: items = [];
        }
        const uniqueBrands = [...new Set(items.map(item => item.brand))];
        return ['All', ...uniqueBrands.sort()];
      };
    
      const getProducts = async () => {
        setLoading(true);
        try {
          const [productsResponse, orderResponse] = await Promise.all([
            axios.get('https://arborconnect-267f83cd8e65.herokuapp.com/products'),
            axios.get('https://arborconnect-267f83cd8e65.herokuapp.com/order')
          ]);
    
          const { data: productsData } = productsResponse;
          const { data: orderData } = orderResponse;
    
          console.log('Fetched order data:', orderData);
    
          setFlowerItems(orderProducts(productsData.flower, orderData.productOrder.flower));
          setConcentratesItems(orderProducts(productsData.concentrate, orderData.productOrder.concentrate));
          setCartridgesItems(orderProducts(productsData.cartridge, orderData.productOrder.cartridge));
          setEdiblesItems(orderProducts(productsData.edible, orderData.productOrder.edible));
          setPrerollsItems(orderProducts(productsData.preroll, orderData.productOrder.preroll));
    
          setOrderStructure(orderData);
    
          await fetchSolventlessProducts();
    
        } catch (error) {
          console.error(`Error occurred while fetching products:`, error);
        } finally {
          setLoading(false);
        }
      };
    
      const orderProducts = (products, order) => {
        if (!order || order.length === 0) return products;
        const productMap = new Map(products.map(p => [p.productId, p]));
        return order
          .filter(id => productMap.has(id))
          .map(id => productMap.get(id))
          .concat(products.filter(p => !order.includes(p.productId)));
      };
    
      const fetchSolventlessProducts = async () => {
        try {
          const response = await axios.get('https://arborconnect-267f83cd8e65.herokuapp.com/solventless-products');
          const { solventless, solventlessCartridges, brandOrderSolventless, brandOrderSolventlessCart } = response.data;
          
          setSolventlessBrandOrder(brandOrderSolventless);
          setSolventlessCartBrandOrder(brandOrderSolventlessCart);
          setSolventlessItems(solventless);
          setSolventlessCartItems(solventlessCartridges);
    
        } catch (error) {
          console.error('Error fetching solventless products:', error);
        }
      };
    
      // const handleOnDragEnd = (category) => {
      //   return async (result) => {
      //     if (!result.destination) return;
    
      //     const items = {
      //       flower: flowerItems,
      //       concentrate: concentratesItems,
      //       cartridge: cartridgesItems,
      //       edible: ediblesItems,
      //       preroll: prerollsItems
      //     }[category];
    
      //     const setItems = {
      //       flower: setFlowerItems,
      //       concentrate: setConcentratesItems,
      //       cartridge: setCartridgesItems,
      //       edible: setEdiblesItems,
      //       preroll: setPrerollsItems
      //     }[category];
    
      //     const newItems = Array.from(items);
      //     const [reorderedItem] = newItems.splice(result.source.index, 1);
      //     newItems.splice(result.destination.index, 0, reorderedItem);
    
      //     setItems(newItems);
    
      //     const newOrder = newItems.map(item => item.productId);
      //     updateOrder({ [category]: newOrder });
      //   };
      // };
    
      // const updateOrder = async (newOrder) => {
      //   try {
      //     const currentOrder = { ...orderStructure.productOrder };
      //     const updatedOrder = { ...currentOrder, ...newOrder };
          
      //     const response = await axios.post('https://arborconnect-267f83cd8e65.herokuapp.com/update-order', {
      //       productOrder: updatedOrder,
      //       brandOrder: orderStructure.brandOrder
      //     });
          
      //     setOrderStructure(prevState => ({
      //       ...prevState,
      //       productOrder: updatedOrder
      //     }));
          
      //     console.log('Order updated successfully:', response.data);
      //   } catch (error) {
      //     console.error('Error updating order:', error);
      //   }
      // };
      
      
      const updateOrder = async (newOrder) => {
        try {
          const currentOrder = { ...orderStructure.productOrder };
          const updatedOrder = { ...currentOrder, ...newOrder };
          
          const response = await axios.post('https://arborconnect-267f83cd8e65.herokuapp.com/update-order', {
            productOrder: updatedOrder,
            brandOrder: orderStructure.brandOrder
          });
          
          setOrderStructure(prevState => ({
            ...prevState,
            productOrder: updatedOrder
          }));
          
          console.log('Order updated successfully:', response.data);
      
          // Refetch products to ensure frontend is in sync with backend
          await getProducts();
        } catch (error) {
          console.error('Error updating order:', error);
        }
      };
      
      const handleOnDragEnd = (category) => {
        return async (result) => {
          if (!result.destination) return;
      
          const items = {
            flower: flowerItems,
            concentrate: concentratesItems,
            cartridge: cartridgesItems,
            edible: ediblesItems,
            preroll: prerollsItems
          }[category];
      
          const setItems = {
            flower: setFlowerItems,
            concentrate: setConcentratesItems,
            cartridge: setCartridgesItems,
            edible: setEdiblesItems,
            preroll: setPrerollsItems
          }[category];
      
          const newItems = Array.from(items);
          const [reorderedItem] = newItems.splice(result.source.index, 1);
          newItems.splice(result.destination.index, 0, reorderedItem);
      
          setItems(newItems);
      
          // Update filteredItems state
          setFilteredItems(prevFilteredItems => ({
            ...prevFilteredItems,
            [category]: newItems
          }));
      
          const newOrder = newItems.map(item => item.productId);
          updateOrder({ [category]: newOrder });
        };
      };
      const handleSolventlessBrandDragEnd = async (result) => {
        if (!result.destination) return;
      
        const newBrandOrder = Array.from(solventlessBrandOrder);
        const [reorderedItem] = newBrandOrder.splice(result.source.index, 1);
        newBrandOrder.splice(result.destination.index, 0, reorderedItem);
      
        setSolventlessBrandOrder(newBrandOrder);
        await updateSolventlessOrder(newBrandOrder, solventlessItems, solventlessCartItems, solventlessCartBrandOrder);
      };
    
      const handleSolventlessProductDragEnd = async (result) => {
        if (!result.destination) return;
      
        const brandId = result.source.droppableId.split('-')[1];
        
        const newItems = { ...solventlessItems };
        const brandItems = newItems[brandId];
        const [reorderedItem] = brandItems.splice(result.source.index, 1);
        brandItems.splice(result.destination.index, 0, reorderedItem);
      
        newItems[brandId] = brandItems;
      
        setSolventlessItems(newItems);
        await updateSolventlessOrder(solventlessBrandOrder, newItems, solventlessCartItems, solventlessCartBrandOrder);
      };
    // Add this function near the other order-related functions
const updateSolventlessOrder = async (brandOrder, products, cartridges, cartBrandOrder) => {
  try {
    const solventlessProductOrder = {};
    brandOrder.forEach(brand => {
      solventlessProductOrder[brand] = products[brand].map(product => product.productId);
    });

    const solventlessCartridgeOrder = {};
    cartBrandOrder.forEach(brand => {
      solventlessCartridgeOrder[brand] = cartridges[brand].map(product => product.productId);
    });

    const updatedOrder = {
      ...orderStructure.productOrder,
      solventless: solventlessProductOrder,
      'solventless-cart': solventlessCartridgeOrder
    };

    const updatedBrandOrder = {
      ...orderStructure.brandOrder,
      solventless: brandOrder,
      'solventless-cart': cartBrandOrder
    };

    await axios.post('https://arborconnect-267f83cd8e65.herokuapp.com/update-order', {
      productOrder: updatedOrder,
      brandOrder: updatedBrandOrder
    });

    setOrderStructure({
      productOrder: updatedOrder,
      brandOrder: updatedBrandOrder
    });

  } catch (error) {
    console.error('Error updating solventless order:', error);
  }
};

// Move these functions to be defined before they are used
const handleSolventlessCartBrandDragEnd = async (result) => {
  if (!result.destination) return;

  const newBrandOrder = Array.from(solventlessCartBrandOrder);
  const [reorderedItem] = newBrandOrder.splice(result.source.index, 1);
  newBrandOrder.splice(result.destination.index, 0, reorderedItem);

  setSolventlessCartBrandOrder(newBrandOrder);

  await updateSolventlessOrder(solventlessBrandOrder, solventlessItems, solventlessCartItems, newBrandOrder);
};

const handleSolventlessCartDragEnd = async (result) => {
  if (!result.destination) return;

  const brandId = result.source.droppableId.split('-')[1];
  
  const newItems = { ...solventlessCartItems };
  const brandItems = newItems[brandId];
  const [reorderedItem] = brandItems.splice(result.source.index, 1);
  brandItems.splice(result.destination.index, 0, reorderedItem);

  newItems[brandId] = brandItems;

  setSolventlessCartItems(newItems);

  await updateSolventlessOrder(solventlessBrandOrder, solventlessItems, newItems, solventlessCartBrandOrder);
};

// Make sure these functions are defined before the useEffect hooks and the return statement
      useEffect(() => {
        getProducts();
      }, []);
    
      useEffect(() => {
        const filterItems = (items) => {
          return items.filter(item => 
            (item.strain.toLowerCase().includes(search.toLowerCase()) || item.brand.toLowerCase().includes(search.toLowerCase())) &&
            (brandFilter === 'All' || item.brand === brandFilter) &&
            (stockFilter === 'All' || (stockFilter === 'InStock' && item.inStock === 'true') || (stockFilter === 'OutOfStock' && item.inStock !== 'true')) &&
            (locationFilter === 'All' || (locationFilter === 'Reno' && item.reno === 'true') || (locationFilter === 'EastBay' && item.reno !== 'true'))|| (locationFilter === 'Reno' && item.reno === true)
          );
        };
    
        const filterSolventlessItems = (items) => {
          const filteredItems = {};
          Object.entries(items).forEach(([brand, products]) => {
            const filteredProducts = products.filter(item =>
              (item.strain.toLowerCase().includes(search.toLowerCase()) || brand.toLowerCase().includes(search.toLowerCase())) &&
              (brandFilter === 'All' || brand === brandFilter) &&
              (stockFilter === 'All' || (stockFilter === 'InStock' && item.inStock === 'true') || (stockFilter === 'OutOfStock' && item.inStock !== 'true')) &&
              (locationFilter === 'All' || (locationFilter === 'Reno' && item.reno === 'true') || (locationFilter === 'Reno' && item.reno === true) ||(locationFilter === 'EastBay' && item.reno !== 'true'))
            );
            if (filteredProducts.length > 0) {
              filteredItems[brand] = filteredProducts;
            }
          });
          return filteredItems;
        };
    
        const filteredSolventless = filterSolventlessItems(solventlessItems);
        const filteredSolventlessCart = filterSolventlessItems(solventlessCartItems);
        
        setFilteredItems(prevFilteredItems => ({
          ...prevFilteredItems,
          flower: filterItems(flowerItems),
          solventless: filteredSolventless,
          'solventless-cart': filteredSolventlessCart,
          concentrate: filterItems(concentratesItems),
          cartridge: filterItems(cartridgesItems),
          edible: filterItems(ediblesItems),
          preroll: filterItems(prerollsItems)
        }));
    
        setSolventlessBrandOrder(prevOrder => 
          prevOrder.filter(brand => brand in filteredSolventless)
        );
        setSolventlessCartBrandOrder(prevOrder => 
          prevOrder.filter(brand => brand in filteredSolventlessCart)
        );
    
      }, [search, brandFilter, stockFilter, locationFilter, flowerItems, solventlessItems, solventlessCartItems, concentratesItems, cartridgesItems, ediblesItems, prerollsItems]);
    
      return (
        <StyledAdminContainer>
          <StyledHeader>
            <Container fluid>
              <Row className="align-items-center">
                <Col xs={4} md={2}>
                  <StyledLogo src="/Images/logo.png" alt="logo" />
                </Col>
                <Col xs={4} md={8} className="text-center">
                  <h1 className="mb-3">Inventory</h1>
                  <Form className="d-flex flex-wrap justify-content-center align-items-center">
                    <Form.Group className="me-2 mb-2">
                      <StyledSelect 
                        onChange={(e) => {
                          setFilter(e.target.value);
                          setBrandFilter('All');
                        }}
                      >
                        <option value="All">All Products</option>
                        <option value="Flower">Flower</option>
                        <option value="Solventless">Solventless</option>
                        <option value="Solventless-Cart">Solventless Carts</option>
                        <option value="Concentrate">Concentrate</option>
                        <option value="Cartridge">Cartridge</option>
                        <option value="Edible">Edible</option>
                        <option value="Preroll">Preroll</option>
                      </StyledSelect>
                    </Form.Group>
                    <Form.Group className="me-2 mb-2">
                      <StyledSelect 
                        onChange={(e) => setBrandFilter(e.target.value)}
                        value={brandFilter}
                      >
                        {(filter === 'All' ? getAllBrands() : getBrandsByType(filter)).map(brand => (
                          <option key={brand} value={brand}>{brand}</option>
                        ))}
                      </StyledSelect>
                    </Form.Group>
                    <Form.Group className="me-2 mb-2">
                      <StyledSelect 
                        onChange={(e) => setStockFilter(e.target.value)}
                        value={stockFilter}
                      >
                        <option value="All">All Stock</option>
                        <option value="InStock">In Stock</option>
                        <option value="OutOfStock">Out of Stock</option>
                      </StyledSelect>
                    </Form.Group>
                    <Form.Group className="me-2 mb-2">
                      <StyledSelect 
                        onChange={(e) => setLocationFilter(e.target.value)}
                        value={locationFilter}
                      >
                        <option value="All">All Locations</option>
                        <option value="EastBay">East Bay</option>
                        <option value="Reno">Reno</option>
                      </StyledSelect>
                    </Form.Group>
                    <Form.Group className="mb-2 position-relative">
                      <StyledSearchInput 
                        type="text" 
                        placeholder="Search..." 
                        value={search} 
                        onChange={e => setSearch(e.target.value)}
                      />
                      <FiSearch style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }} />
                    </Form.Group>
                  </Form>
                </Col>
                <Col xs={4} md={2} className="text-end">
                  <StyledButton variant="primary" onClick={handleShow}>
                    <FiPlus />
                  </StyledButton>
                  <Button variant="outline-secondary" className="ms-2" onClick={handleLogout}>Logout</Button>
                </Col>
              </Row>
            </Container>
          </StyledHeader>
          <Container fluid>
        {filter === 'All' && (
          <>
            <center>            <h2 style={{marginBottom: -60, marginTop:100}}>Flower</h2>

            <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('flower')} items={filteredItems.flower} allItems={flowerItems} setItems={setFlowerItems} dropId="flowerItems" category="flower" />
            <h2 style={{marginBottom: -60, marginTop:60}}>Solventless</h2>

            <BrandDragNDrop
              brands={solventlessBrandOrder}
              onDragEnd={handleSolventlessBrandDragEnd}
            >
              {(provided) => (
                <>
                  {solventlessBrandOrder.map(brand => (
                    <div key={brand}>
                     <h3 style={{marginBottom: -60, marginTop:30}}>{brand}</h3>
                      <DragNDrop
                        editProduct={editProduct}
                        deleteProduct={deleteProduct}
                        handleOnDragEnd={handleSolventlessProductDragEnd}
                        items={filteredItems.solventless[brand] || []}
                        allItems={solventlessItems[brand] || []}
                        setItems={(newItems) => setSolventlessItems(prev => ({...prev, [brand]: newItems}))}
                        dropId={`solventlessItems-${brand}`}
                        category="solventless"
                      />
                    </div>
                  ))}
                </>
              )}
            </BrandDragNDrop>
            </center>
           <center>
             <h2 style={{marginBottom: -60, marginTop:30}}>Solventless Cartridges</h2>
            
            <BrandDragNDrop
              brands={solventlessCartBrandOrder}
              onDragEnd={handleSolventlessCartBrandDragEnd}
            >
              {(provided) => (
                <>
                  {solventlessCartBrandOrder.map(brand => (
                    <div key={brand}>
<h3 style={{marginBottom: -60, marginTop:30}}>{brand}</h3>
                      <DragNDrop 
                        editProduct={editProduct} 
                        deleteProduct={deleteProduct} 
                        handleOnDragEnd={handleSolventlessCartDragEnd} 
                        items={filteredItems['solventless-cart'][brand] || []} 
                        allItems={solventlessCartItems[brand] || []} 
                        setItems={(newItems) => setSolventlessCartItems(prev => ({...prev, [brand]: newItems}))} 
                        dropId={`solventlessCartItems-${brand}`} 
                        category="solventless-cart" 
                      />
                    </div>
                  ))}
                </>
              )}
            </BrandDragNDrop>
            <h2 style={{marginBottom: -60, marginTop:60}}>Concentrates</h2>
            <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('concentrate')} items={filteredItems.concentrate} allItems={concentratesItems} setItems={setConcentratesItems} dropId="concentratesItems" category="concentrate" />
            <h2 style={{marginBottom: -60, marginTop:60}}>Cartridges</h2>

            <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('cartridge')} items={filteredItems.cartridge} allItems={cartridgesItems} setItems={setCartridgesItems} dropId="cartridgesItems" category="cartridge" />
            <h2 style={{marginBottom: -60, marginTop:60}}>Edibles</h2>

            <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('edible')} items={filteredItems.edible} allItems={ediblesItems} setItems={setEdiblesItems} dropId="ediblesItems" category="edible" />
            <h2 style={{marginBottom: -60, marginTop:60}}>Prerolls</h2>

            <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('preroll')} items={filteredItems.preroll} allItems={prerollsItems} setItems={setPrerollsItems} dropId="prerollsItems" category="preroll" />

         </center> </>
        )}
        {filter === 'Solventless' && (
          <center>
          <BrandDragNDrop
            brands={solventlessBrandOrder}
            onDragEnd={handleSolventlessBrandDragEnd}
          >
            {(provided) => (
              <>
                {solventlessBrandOrder.map(brand => (
                  <div  key={brand}>
                    <h3 style={{marginBottom: -60, marginTop:30}}>{brand}</h3>
                    <DragNDrop
                      editProduct={editProduct}
                      deleteProduct={deleteProduct}
                      handleOnDragEnd={handleSolventlessProductDragEnd}
                      items={filteredItems.solventless[brand] || []}
                      allItems={solventlessItems[brand] || []}
                      setItems={(newItems) => setSolventlessItems(prev => ({...prev, [brand]: newItems}))}
                      dropId={`solventlessItems-${brand}`}
                      category="solventless"
                    />
                  </div>
                ))}
              </>
            )}
          </BrandDragNDrop>
          </center>
        )}
        {filter === 'Solventless-Cart' && (
          <center>
          <BrandDragNDrop
            brands={solventlessCartBrandOrder}
            onDragEnd={handleSolventlessCartBrandDragEnd}
          >
            {(provided) => (
              <>
                {solventlessCartBrandOrder.map(brand => (
                  <div key={brand}>
                   <h3 style={{marginBottom: -60, marginTop:30}}>{brand}</h3>
                    <DragNDrop 
                      editProduct={editProduct} 
                      deleteProduct={deleteProduct} 
                      handleOnDragEnd={handleSolventlessCartDragEnd} 
                      items={filteredItems['solventless-cart'][brand] || []} 
                      allItems={solventlessCartItems[brand] || []} 
                      setItems={(newItems) => setSolventlessCartItems(prev => ({...prev, [brand]: newItems}))} 
                      dropId={`solventlessCartItems-${brand}`} 
                      category="solventless-cart" 
                    />
                  </div>
                ))}
              </>
            )}
          </BrandDragNDrop></center>
        )}
        {filter === 'Flower' && (
          <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('flower')} items={filteredItems.flower} allItems={flowerItems} setItems={setFlowerItems} dropId="flowerItems" category="flower" />
        )}
        {filter === 'Concentrate' && (
          <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('concentrate')} items={filteredItems.concentrate} allItems={concentratesItems} setItems={setConcentratesItems} dropId="concentratesItems" category="concentrate" />
        )}
        {filter === 'Edible' && (
          <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('edible')} items={filteredItems.edible} allItems={ediblesItems} setItems={setEdiblesItems} dropId="ediblesItems" category="edible" />
        )}
        {filter === 'Preroll' && (
          <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('preroll')} items={filteredItems.preroll} allItems={prerollsItems} setItems={setPrerollsItems} dropId="prerollsItems" category="preroll" />
        )}
        {filter === 'Cartridge' && (
          <DragNDrop editProduct={editProduct} deleteProduct={deleteProduct} handleOnDragEnd={handleOnDragEnd('cartridge')} items={filteredItems.cartridge} allItems={cartridgesItems} setItems={setCartridgesItems} dropId="cartridgesItems" category="cartridge" />
        )}
      </Container>
      
      <Modal show={showModal} onHide={handleClose} centered>
        <AddProduct
          editProduct={editProduct}
          productToEdit={productToEdit}
          setProductToEdit={setProductToEdit}
          showModal={showModal}
          onHide={handleClose}
          strain={strain}
          setStrain={setStrain}
          category={category}
          setCategory={setCategory}
          quantities={quantities}
          setQuantities={setQuantities}
          prices={prices}
          setPrices={setPrices}
          description={description}
          setDescription={setDescription}
          image={image}
          setImage={setImage}
          onSale={onSale}
          setOnSale={setOnSale}
          inStock={inStock}
          setInStock={setInStock}
          reno={reno}
          setReno={setReno}
          brand={brand}
          setBrand={setBrand}
          handlePriceChange={handlePriceChange}
          type={type}
          setType={setType}
        />
      </Modal>
        </StyledAdminContainer>
    );
};

export default Admin;