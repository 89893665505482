import React, { useState, useRef, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './Products.css';
import { motion, useInView } from 'framer-motion';

const Products = ({ menuItems, title }) => {
  const [toggledIndex, setToggledIndex] = useState(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, threshold: 0.1 });

  const handleCardClick = (index) => {
    setToggledIndex(toggledIndex === index ? null : index);
  };

  const parseDescription = (description, isEdible) => {
    if (isEdible) {
      const lastColonIndex = description.lastIndexOf(':');
      if (lastColonIndex !== -1) {
        const desc = description.substring(0, lastColonIndex).trim();
        const dosage = description.substring(lastColonIndex + 1).trim();
        return {
          description: desc.replace(/~/g, '\n'),
          dosage: dosage.replace(/~/g, '\n')
        };
      }
      return { 
        description: description.replace(/~/g, '\n'), 
        dosage: '' 
      };
    }

    const formattedDescription = description.replace(/~/g, '\n');
    const parts = formattedDescription.split('}');
    if (parts.length > 1) {
      return {
        lineage: parts[0].trim() + '}',
        description: parts.slice(1).join('}').trim()
      };
    }
    return {
      lineage: '',
      description: formattedDescription.trim()
    };
  };

  const renderWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const cleanupBrand = (brand) => {
    if (!brand) return '';
    return brand.split('-')[0].trim();
  };

  const filteredItems = menuItems.filter(item => item.inStock === "true");

  useEffect(() => {
    const equalizeCardHeights = () => {
      const cards = document.querySelectorAll('.product-card');
      let maxHeight = 0;
      
      cards.forEach(card => {
        card.style.height = 'auto';
        const cardContent = card.querySelector('.product-card-content');
        const cardBody = card.querySelector('.product-card-body');
        if (cardContent && cardBody) {
          cardContent.style.height = 'auto';
          const contentHeight = cardContent.offsetHeight;
          const bodyHeight = cardBody.offsetHeight;
          const totalHeight = contentHeight + bodyHeight;
          maxHeight = Math.max(maxHeight, totalHeight);
        }
      });
      
      cards.forEach(card => {
        card.style.height = `${maxHeight}px`;
        const cardContent = card.querySelector('.product-card-content');
        if (cardContent) {
          cardContent.style.height = `${maxHeight - card.querySelector('.product-card-body').offsetHeight}px`;
        }
      });
    };

    equalizeCardHeights();
    window.addEventListener('resize', equalizeCardHeights);

    return () => window.removeEventListener('resize', equalizeCardHeights);
  }, [filteredItems, toggledIndex]);

  useEffect(() => {
    const adjustLineageFont = () => {
      const lineageElements = document.querySelectorAll('.product-lineage span');
      lineageElements.forEach(element => {
        let fontSize = 16; // Start with the default font size
        element.style.whiteSpace = 'nowrap';
        element.style.overflow = 'hidden';
        element.style.textOverflow = 'ellipsis';
        
        while (element.scrollWidth > element.offsetWidth && fontSize > 8) {
          fontSize--;
          element.style.fontSize = `${fontSize}px`;
        }
      });
    };

    adjustLineageFont();
    window.addEventListener('resize', adjustLineageFont);

    return () => window.removeEventListener('resize', adjustLineageFont);
  }, [filteredItems]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren"
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  return (
    <motion.div 
      className='product-container'
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <center>
        <motion.h2 
          className={`product-title ${title.toLowerCase()}`}
          style={{ color: 'white' }}
          variants={itemVariants}
        >
          {title}
        </motion.h2>
        <Row className="justify-content-center">
          {filteredItems.map((item, index) => {
            let prices;
            try {
              prices = JSON.parse(item.prices);
            } catch (e) {
              console.error("Failed to parse prices for item", item, e);
              prices = [];
            }

            const twoGramPrice = prices.find(p => p.quantity === "2g");
            const eachPrice = prices.find(p => p.quantity === "Each");
            const twoPrice = prices.find(p => p.quantity === "2");

            const isEdible = title === 'Edibles';
            const isCartridge = title === 'Cartridges';
            const parsedDescription = parseDescription(item.description, isEdible);
            const cleanedBrand = cleanupBrand(item.brand);

            return (
              <Col sm={12} md={6} lg={4} key={index}>
                <motion.div variants={itemVariants} className="h-100">
                  <Card className={`product-card ${toggledIndex === index ? 'show-description' : ''}`}>
                    <div className="product-card-content">
                      <div className="product-image-container" onClick={() => handleCardClick(index)}>
                        <Card.Img variant="top" src={item.imageurl} className="product-img-top" />
                        <div className="product-description">
                          {!isEdible && parsedDescription.lineage && (
                            <div className="product-lineage">
                              <strong>Lineage:</strong> 
                              <span>{parsedDescription.lineage}</span>
                            </div>
                          )}
                          <div className="product-description-text">
                            <strong>Description:</strong> 
                            {renderWithLineBreaks(parsedDescription.description)}
                          </div>
                          {isEdible && parsedDescription.dosage && (
                            <div className="product-dosage">
                              <strong>Dosage:</strong> 
                              {renderWithLineBreaks(parsedDescription.dosage)}
                            </div>
                          )}
                        </div>
                      </div>
                      {cleanedBrand === 'Potent Labs' && <img src="/Images/b2g1s.png" alt="Buy 2 Get 1 Free" className="b2g1-icon" />}
                    </div>
                    <Card.Body className="product-card-body">
                      <div className="product-info">
                        <Card.Title className='product-title-item' style={{color: 'white'}}>
                          {item.strain}
                        </Card.Title>
                        {item.type && (
                          <Card.Text className='product-type'>
                            {item.type}
                          </Card.Text>
                        )}
                        <Card.Text className='product-brand'>{cleanedBrand}</Card.Text>
                      </div>
                      <div className="product-price-container">
                        <div className='center-wrapper'>
                          <div>
                            {twoGramPrice && <Card.Text className="product-price-item">2G: ${twoGramPrice.price}</Card.Text>}
                            {eachPrice && <Card.Text className="product-price-item">${eachPrice.price} Each</Card.Text>}
                            {isCartridge && twoPrice && (
                              <Card.Text className="product-price-item">${twoPrice.price / 2} Each (2+)</Card.Text>
                            )}
                          </div>
                          {item.onSale === 'true' && (
                            <img 
                              src="/Images/saletagright.png" 
                              alt="Sale" 
                              className="product-sale-tag"
                            />
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            )
          })}
        </Row>
      </center>
    </motion.div>
  );
};

export default Products;