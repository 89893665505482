import axios from 'axios';

const api = axios.create({
  baseURL: 'https://arborconnect-267f83cd8e65.herokuapp.com',
  withCredentials: true
});

// We don't need the interceptor for adding the token anymore
// because we're using session-based auth with cookies

export default api;