import React, { useState, useRef, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './Flower.css';
import { motion, useInView } from 'framer-motion';

const Flower = ({ menuItems, title }) => {
  const [toggledIndex, setToggledIndex] = useState(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, threshold: 0.1 });

  const handleCardClick = (index) => {
    setToggledIndex(toggledIndex === index ? null : index);
  };

  const parseDescription = (description) => {
    const formattedDescription = description.replace(/~/g, '\n');
    const parts = formattedDescription.split('}');
    if (parts.length > 1) {
      return {
        lineage: parts[0].trim() + '}',
        description: parts.slice(1).join('}').trim()
      };
    }
    return {
      lineage: '',
      description: formattedDescription.trim()
    };
  };

  const renderWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const cleanupBrand = (brand) => {
    if (!brand) return '';
    return brand.split('-')[0].trim();
  };

  const inStockItems = menuItems.filter(item => item.inStock === "true");

  useEffect(() => {
    const equalizeCardHeights = () => {
      const cards = document.querySelectorAll('.card');
      let maxHeight = 0;
      
      cards.forEach(card => {
        card.style.height = 'auto';
      });
      
      cards.forEach(card => {
        const height = card.offsetHeight;
        maxHeight = Math.max(maxHeight, height);
      });
      
      cards.forEach(card => {
        card.style.height = `${maxHeight}px`;
      });
    };

    equalizeCardHeights();
    window.addEventListener('resize', equalizeCardHeights);

    return () => window.removeEventListener('resize', equalizeCardHeights);
  }, [inStockItems]);

  useEffect(() => {
    const adjustLineageFont = () => {
      const lineageElements = document.querySelectorAll('.lineage span');
      lineageElements.forEach(element => {
        let fontSize = 16; // Start with the default font size
        element.style.whiteSpace = 'nowrap';
        element.style.overflow = 'hidden';
        element.style.textOverflow = 'ellipsis';
        
        while (element.scrollWidth > element.offsetWidth && fontSize > 8) {
          fontSize--;
          element.style.fontSize = `${fontSize}px`;
        }
      });
    };

    adjustLineageFont();
    window.addEventListener('resize', adjustLineageFont);

    return () => window.removeEventListener('resize', adjustLineageFont);
  }, [inStockItems]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
        duration: 0.4,
        ease: "easeOut"
      }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 100,
        duration: 0.4
      }
    }
  };

  return (
    <motion.div 
      className='flowerr'
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <center>
        <motion.h2 
          className='flower-title' 
          style={{ color: 'white' }}
          variants={itemVariants}
        >
          {title}
        </motion.h2>
        <Row className="justify-content-center">
          {inStockItems.map((item, index) => {
            let prices;
            try {
              prices = JSON.parse(item.prices);
            } catch (e) {
              console.error("Failed to parse prices for item", item, e);
              prices = [];
            }

            const eighthPrice = prices.find(p => p.quantity === "1/8")?.price;
            const fullPrice = prices.find(p => p.quantity === "Full")?.price;
            const halfPrice = prices.find(p => p.quantity === "1/2")?.price;

            const priceCount = [eighthPrice, fullPrice, halfPrice].filter(Boolean).length;
            const priceClass = priceCount === 1 ? 'one-price' : priceCount === 2 ? 'two-prices' : 'three-prices';

            const { lineage, description } = parseDescription(item.description);
            const cleanedBrand = cleanupBrand(item.brand);

            return (
              <Col sm={12} md={6} lg={4} key={index}>
                <motion.div variants={itemVariants} className="h-100">
                  <Card className={`card h-100 d-flex flex-column ${toggledIndex === index ? 'show-description' : ''}`}>
                    <div className="card-image-container flex-grow-1" onClick={() => handleCardClick(index)}>
                      <Card.Img variant="top" src={item.imageurl} className="flower-card-img-top zoomed" />
                      <div className="card-description">
                        {lineage && (
                          <div className="lineage">
                            <strong>Lineage:</strong> <span>{lineage}</span>
                          </div>
                        )}
                        <div className="description">
                          <strong>Description:</strong> {renderWithLineBreaks(description)}
                        </div>
                      </div>
                    </div>
                    <Card.Body className="d-flex flex-column">
                      <div className="flex-grow-1">
                        <Card.Title className='flowertit' style={{ color: 'white' }}>
                          <span className="strain-name">{item.strain}</span>
                        </Card.Title>
                        <Card.Text style={{ color: 'white', minHeight: '24px' }}>{cleanedBrand}</Card.Text>
                        {item.type && (
                          <Card.Text className='product-type'>
                            {item.type}
                          </Card.Text>
                        )}
                      </div>
                      {item.onSale === 'true' && (
                        <img src="/Images/saletagleft.png" className="sale-tag" alt="Sale" />
                      )}
                      <div className={`price-container ${priceClass} mt-auto`}>
                        {eighthPrice && (
                          <div className="price-item">
                            <div className="quantity">EIGHTH:</div>
                            <div className="price">${eighthPrice}</div>
                          </div>
                        )}
                        {fullPrice && (
                          <div className="price-item full">
                            <div className="quantity">FULL:</div>
                            <div className="price">${fullPrice}</div>
                          </div>
                        )}
                        {halfPrice && (
                          <div className="price-item">
                            <div className="quantity">HALF:</div>
                            <div className="price">${halfPrice}</div>
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Col>
            )
          })}
        </Row>
      </center>
    </motion.div>
  );
};

export default Flower;