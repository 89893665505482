import React from 'react';
import './LoadingScreen.css';

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="loading-content">
        <img src="/Images/arborblack5.png" alt="Loading" className="loading-image" />
        <div className="loader">
          <div className="bar-container">
            <img src="/Images/bars2.png" alt="Loading Bar 1" className="bar bar1" />
          </div>
          <div className="bar-container">
            <img src="/Images/bars1.png" alt="Loading Bar 2" className="bar bar2" />
          </div>
          <div className="bar-container">
            <img src="/Images/bars2.png" alt="Loading Bar 3" className="bar bar3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;