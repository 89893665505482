import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Dropdown, InputGroup, FormControl } from 'react-bootstrap'
import axios from 'axios';
import { FiX } from 'react-icons/fi'
// import AddProductForm from './AddProductForm';
import ProductForm from './ProductForm';

const AddProduct = ({handlePriceChange, showModal, onHide, setProductToEdit, productToEdit, editProduct, strain, setStrain, category, setCategory, quantities,setQuantities,image, setImage,onSale, setOnSale,inStock, setInStock,brand, setBrand,prices, setPrices, description, setDescription, type, setType, reno, setReno}) => {

    // const [strain, setStrain] = useState('')
    // const [category, setCategory] = useState('')
    // const [quantities, setQuantities] = useState([])
    // const [prices, setPrices] = useState([])
    // const [description, setDescription] = useState('')
    // const [image, setImage] = useState(null)
    // const [onSale, setOnSale] = useState(false)
    // const [inStock, setInStock] = useState(false)
    // const [brand, setBrand] = useState('')


  const [productData, setProductData] = useState({
    strain: '',
    category: '',
    quantity: [],
    price: [],
    photo: '',
    description: '',
    onSale: false,
    inStock: false
  });

  const handleSelect = (value) => {
    if (productData.quantity.includes(value)) {
      setProductData({
        ...productData,
        quantity: productData.quantity.filter(qty => qty !== value)
      });
    } else {
      setProductData({
        ...productData,
        quantity: [...productData.quantity, value]
      });
    }
  }

  const handleChange = (event) => {
    setProductData({
      ...productData,
      [event.target.name]: event.target.type === "file" ? event.target.files[0] : event.target.value
    });
  }
  const testBackendConnection = async () => {
    try {
      await axios.get(' http://localhost:8000/data');
      console.log('Connection Successful');
    } catch (err) {
      console.log('Failed to connect to the backend', err);
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    let formData = new FormData();
    for (const key in productData) {
      formData.append(key, productData[key]);
    }

    await axios.post(' http://localhost:8000/data', formData);
  }

  const handleEdit = (item) => {
    setStrain(item.strain);
    setCategory(item.category);
    setDescription(item.description);
    setImage(item.image);
    setBrand(item.brand);
    setInStock(item.inStock);
    setOnSale(item.onSale);
    setQuantities(item.quantities);

    showModal();


  }

//   useEffect(() => {
//     testBackendConnection();
//   }, []);

  return (
    <div>
      <center>
      <h1>{productToEdit ? 'Edit Product' : 'Add Product'}</h1></center>
      {/* <AddProductForm handleChange={handleChange} handleSubmit = {handleSubmit} handleSelect={handleSelect} productData={productData} testBackendConnection={testBackendConnection} setProductData ={setProductData} /> */}
      <ProductForm 
        strain={strain} 
        setStrain={setStrain} 
        category={category} 
        setCategory={setCategory} 
        quantities={quantities} 
        setQuantities={setQuantities} 
        prices={prices} 
        setPrices={setPrices} 
        description={description} 
        setDescription={setDescription} 
        image={image} 
        setImage={setImage} 
        onSale={onSale} 
        setOnSale={setOnSale} 
        inStock={inStock} 
        setInStock={setInStock} 
        reno = {reno}
        setReno = {setReno}
        brand={brand} 
        setBrand={setBrand}
        productToEdit = {productToEdit} 
        setProductToEdit={setProductToEdit}
        editProduct = {editProduct}
        handlePriceChange = {handlePriceChange}
        type = {type}
        setType = {setType}

    />
    </div>
  )
}

export default AddProduct