import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';

const BrandDragNDrop = ({ brands, onDragEnd, children }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 100 }}>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="brandList">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {brands.map((brand, index) => (
                  <Draggable key={brand} draggableId={brand} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          display: "flex",
                          width: '500px',
                          marginBottom: '10px',
                          borderRadius: '20px',
                          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                          alignItems: "center",
                          backgroundColor: 'white',
                          padding: '10px',
                          ...provided.draggableProps.style
                        }}
                      >
                        <div style={{ margin: 10, flexGrow: 1 }}>{brand}</div>
                       
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {children && children(provided)}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default BrandDragNDrop;