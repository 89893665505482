import background from './trees.jpg';
import './App.css';
import Home from './components/Home';
import NavBar from './components/NavBar';
import Products from './components/Products';
import flower from './mockFlower.png'
import preroll from './MockPreroll.png'
import Solventless from './components/Solventless';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Arbor from './pages/Arbor';
import Login from './pages/Login';
import Admin from './pages/Admin';
import { AuthProvider } from './AuthProvider';
import ProtectedRoute from './ProtectedRoute';
import LoadingScreen from './components/LoadingScreen';


// import DragNDrop from './components/DragNDrop';


function App() {


  const flowerMenuItems = [
    { title: 'Runtz', price: '\$10', img: flower },
    { title: 'Zkittlez', price: '\$12', img: flower },
    { title: 'Zushi', price: '\$15', img: flower },
    // Add more items as needed
  ];
  const concentrateMenuItems = [
    { title: 'Runtz', price: '\$10', img: flower },
    { title: 'Zkittlez', price: '\$12', img: flower },
    { title: 'Zushi', price: '\$15', img: flower },
    // Add more items as needed
  ];
  const cartridgeMenuItems = [
    { title: 'Runtz', price: '\$10', img: flower },
    { title: 'Zkittlez', price: '\$12', img: flower },
    { title: 'Zushi', price: '\$15', img: flower },
    // Add more items as needed
  ];
  const edibleMenuItems = [
    { title: 'Runtz', price: '\$10', img: flower },
    { title: 'Zkittlez', price: '\$12', img: flower },
    { title: 'Zushi', price: '\$15', img: flower },
    // Add more items as needed
  ];
  const prerollMenuItems = [
    { title: 'Runtz', price: '\$10', img: preroll },
    { title: 'Zkittlez', price: '\$12', img: preroll },
    { title: 'Zushi', price: '\$15', img: preroll },
    // Add more items as needed
  ];
  const solventlessMenuItems = [
    {brand:'Professor Sift', 
    strains: [{title: 'GMO', type: 'Cold Cure', qty:'2G', price:'$140', sale: false}, 
             {title: 'Guava-Z', type: 'Cold Cure', qty: '2G', price: '$140', sale: false},
             {title: 'Tallymon', type: 'Cold Cure', qty:'2G', price:'$140', sale: false}, 
             {title: 'Pink Yuzu', type: 'Cold Cure', qty: '2G', price: '$140', sale: false},]},
    {brand: 'Baked Potato', 
    strains: [{title: 'Zlurpee', type: 'Cold Cure', qty: '2G', price: '$60', sale: true },
             {title: 'Blue Icee', type: 'Cold Cure', qty: '2G', price: '$70', sale: false },
             {title: 'Gush Mints', type: 'Cold Cure', qty: '2G', price: '$70', sale: false },
             {title: 'Rainbow Belts', type: 'Cold Cure', qty: '2G', price: '$70', sale: false },]}
  ];
  

  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Arbor solventlessMenuItems={solventlessMenuItems} flowerMenuItems={flowerMenuItems} prerollMenuItems={prerollMenuItems} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        } />
      </Routes>
    </Router>
  </AuthProvider>
  // <LoadingScreen/>
);
}

export default App;
