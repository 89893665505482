import React, { useRef, useMemo } from 'react';
import './Solventless.css';
import { motion, useInView } from 'framer-motion';

const Solventless = ({ menuItems = {}, title, brandOrder = [], isSolventlessCart = false }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, threshold: 0.1 });

  // Function to shuffle an array
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Use useMemo to create a shuffled brand order that remains consistent during re-renders
  const shuffledBrandOrder = useMemo(() => {
    return isSolventlessCart ? brandOrder : shuffleArray(brandOrder);
  }, [brandOrder, isSolventlessCart]);

  const isSpecialStrain = (strain) => {
    if (strain && typeof strain === 'string' && strain.endsWith('*')) {
      return strain.slice(0, -1); // Remove the last character (*)
    }
    return false;
  };

  const renderPrice = (prices, sale, type, strain) => {
    const twoGramPrice = prices.find(p => p.quantity === "2g");
    const oneGramPrice = prices.find(p => p.quantity === "1g");
    const eighthPrice = prices.find(p => p.quantity === "1/8");
    const specialStrain = isSpecialStrain(strain);

    return (
      <div className={`solvprice ${specialStrain ? 'special-price' : ''}`} style={{top: sale === 'true' ? '0px' : '7px'}}>
        <span>
          {eighthPrice && (
            <span className="price-item">
              <span className="quantity">3.5G:</span>
              <span className={`price ${specialStrain ? 'special-price-text' : ''}`}>${eighthPrice.price}</span>
            </span>
          )}
          {twoGramPrice && (
            <span className="price-item">
              <span className="quantity">2G:</span>
              <span className={`price ${specialStrain ? 'special-price-text' : ''}`}>${twoGramPrice.price}</span>
            </span>
          )}
          {oneGramPrice && (
            <span className="price-item">
              <span className="quantity">1G:</span>
              <span className={`price ${specialStrain ? 'special-price-text' : ''}`}>${oneGramPrice.price}</span>
            </span>
          )}
        </span>
        {sale === 'true' && (
          <img 
            src="/Images/saletagright.png" 
            alt="Sale" 
            style={{position:"relative", right: "5px"}}
          />
        )}
      </div>
    );
  };

  const renderCartridgePrice = (items) => {
    if (items.length === 0) return null;
    let prices;
    try {
      prices = JSON.parse(items[0].prices);
    } catch (e) {
      console.error("Failed to parse prices for item", items[0], e);
      return null;
    }
  
    const twoGramPrice = prices.find(p => p.quantity === "2g");
    const oneGramPrice = prices.find(p => p.quantity === "1g");
  
    return (
      <div className='brand-price'>
        {oneGramPrice && <span>${oneGramPrice.price} Each</span>}
        {twoGramPrice && oneGramPrice && <span> | </span>}
        {twoGramPrice && (
          <span>${twoGramPrice.price/2} Each <span className="smaller-text">(2+)</span></span>
        )}
      </div>
    );
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren"
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  // Filter out items that are not in stock and sort by strain name length
  const filteredAndSortedMenuItems = Object.fromEntries(
    Object.entries(menuItems).map(([brand, items]) => [
      brand,
      items
        .filter(item => item.inStock === "true")
        .sort((a, b) => a.strain.length - b.strain.length)
    ])
  );

  return (
    <motion.div 
      style={{ marginTop: "50px", marginBottom: "50px", padding:'2px' }}
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <center>
        <motion.div 
          className='solvbg'
          variants={itemVariants}
        >
          <motion.h1 className='solv-title' variants={itemVariants}>{title}</motion.h1>
          {shuffledBrandOrder.length > 0 ? (
            shuffledBrandOrder.map((brand) => (
              filteredAndSortedMenuItems[brand] && filteredAndSortedMenuItems[brand].length > 0 && (
                <motion.div key={brand} variants={itemVariants}>
                  <h2 className='brand'>{brand}</h2>
                  {isSolventlessCart && renderCartridgePrice(filteredAndSortedMenuItems[brand])}
                  {filteredAndSortedMenuItems[brand].map((item) => (
                    <motion.div className='stuff' key={item.strain} variants={itemVariants}>
                      <div className='strain-price-container'>
                        <div className='center-wrapper'>
                          <div className='solvstrain'>{isSpecialStrain(item.strain) || item.strain}</div>
                          {!isSolventlessCart && renderPrice(JSON.parse(item.prices), item.onSale, item.type, item.strain)}
                          {item.type === 'Fresh Press' && (
                            <img src="/Images/freshpress.png" alt="Fresh Press" style={{ position:'absolute'}} className="fresh-press" />
                          )}
                        </div>
                      </div>
                      <p className='solvlineage'>{item.description}</p>
                    </motion.div>
                  ))}
                </motion.div>
              )
            ))
          ) : (
            <motion.p variants={itemVariants}>No products available.</motion.p>
          )}
        </motion.div>
      </center>
    </motion.div>
  );
}

export default Solventless;