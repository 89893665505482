import React, { useEffect, useState } from 'react'
import { Button, Form, Dropdown } from 'react-bootstrap'
import axios from 'axios'
import {ThreeDots} from 'react-loader-spinner'; // Import the loading spinner at the top of your file


const ProductForm = ({handlePriceChange,productToEdit, setProductToEdit, strain, setStrain, category, setCategory, quantities, setQuantities, prices, setPrices, description, setDescription, image, setImage, onSale, setOnSale, inStock, setInStock, brand, setBrand, type, setType, reno, setReno}) => {

//     const handleSubmit = (event) => {
//         event.preventDefault();

//         if (category === "" || quantities.length === 0) {
//             alert("Please fill all fields");
//             return;
//         }

//         const formData = new FormData();
//         formData.append('strain', strain);
//         formData.append('category', category);
//         formData.append('prices', JSON.stringify(prices));
//         formData.append('description', description);
//         formData.append('image', image); 
//         formData.append('onSale', onSale);
//         formData.append('inStock', inStock);
//         formData.append('brand', brand);



//   if (productToEdit) {
//     // Update existing product
//     axios.put(`http://localhost:8000/update-product/${productToEdit.productId}`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     })
//     .then(response => {
//       console.log(response);
//       window.location.reload();
//       setProductToEdit(null); // Clear the productToEdit state
//     })
//     .catch(error => {
//       console.log(error);
//     });
//   } else {
//     // Add new product
//     axios.post('http://localhost:8000/add-product', formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     })
//     .then(response => {
//       console.log(response);
//       window.location.reload();
//     })
//     .catch(error => {
//       console.log(error);
//     });
//   }


// }

const backend_url = process.env.URL;

const [loading, setLoading] = useState(false);

const handleSubmit = (event) => {
  event.preventDefault();

  if (category === "" || quantities.length === 0) {
    alert("Please fill all fields");
    return;
  }

  setLoading(true); // Start loading

  const formData = new FormData();
  formData.append('strain', strain);
  formData.append('category', category);
  formData.append('prices', JSON.stringify(prices));
  formData.append('description', description);
  formData.append('image', image); 
  formData.append('onSale', onSale);
  formData.append('inStock', inStock);
  formData.append('reno', reno);

  formData.append('brand', brand);
  formData.append('type', type);

  if (productToEdit) {
    // Update existing product
    
    // axios.put(`https://arborconnect-267f83cd8e65.herokuapp.com/update-product/${productToEdit.productId}`, formData, {
      axios.put(`https://arborconnect-267f83cd8e65.herokuapp.com/update-product/${productToEdit.productId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log(response);
      window.location.reload();
      setProductToEdit(null); // Clear the productToEdit state
      setLoading(false); // Stop loading
    })
    .catch(error => {
      console.log(error);
      setLoading(false); // Stop loading
    });
  } else {
    // Add new product
    console.log(formData.type)
    // axios.post('https://arborconnect-267f83cd8e65.herokuapp.com/add-product', formData, {
      axios.post('https://arborconnect-267f83cd8e65.herokuapp.com/add-product', formData, {

      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log(response);
      window.location.reload();
      setLoading(false); // Stop loading
    })
    .catch(error => {
      console.log(error);
      setLoading(false); // Stop loading
    });
  }
};



    const handleQuantityChange = (quantity) => {
        const newQuantities = [...quantities]
        const newPrices = [...prices]
        if (newQuantities.includes(quantity)) {
        const index = newQuantities.indexOf(quantity)
        newQuantities.splice(index, 1)
        newPrices.splice(index, 1)
        setPrices(newPrices)
        } else {
        newQuantities.push(quantity)
        }
        setQuantities(newQuantities)
    }
  

    const handleImageChange = (event) => {
        setImage(event.target.files[0]);   
     }


  return (
    <Form encType='multipart/form-data' onSubmit={handleSubmit} style={{margin:'20px'}}>
        <Form.Group controlId="strain">
            <Form.Label>Strain:</Form.Label>
            <Form.Control type="text" value={strain} onChange={e => setStrain(e.target.value)} required />
        </Form.Group>
        <Form.Group controlId="category">
            <Form.Label>Category:</Form.Label>
            <Form.Control as="select" value={category} onChange={e => setCategory(e.target.value)} required>
                <option disabled={category !== ''}>Select a category</option>
                <option value='flower'>flower</option>
                <option value='solventless'>solventless</option>
                <option value='concentrate'>concentrate</option>
                <option value='cartridge'>cartridges</option>
                <option value='edible'>edibles</option>
                <option value='preroll'>prerolls</option>
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="quantities">
            <Form.Label>Quantities:</Form.Label>
            <Dropdown onSelect={handleQuantityChange} style={{display: 'flex', flexDirection: 'row'}} required>
                <Dropdown.Item eventKey="1g" style={{backgroundColor: quantities.includes("1g") ? '#b8dafd' : '#f8f8f8'}}>1g</Dropdown.Item>
                <Dropdown.Item eventKey="2g" style={{backgroundColor: quantities.includes("2g") ? '#b8dafd' : '#f8f8f8'}}>2g</Dropdown.Item>
                <Dropdown.Item eventKey="1/8" style={{backgroundColor: quantities.includes("1/8") ? '#b8dafd' : '#f8f8f8'}}>1/8</Dropdown.Item>
                <Dropdown.Item eventKey="1/4" style={{backgroundColor: quantities.includes("1/4") ? '#b8dafd' : '#f8f8f8'}}>1/4</Dropdown.Item>
                <Dropdown.Item eventKey="1/2" style={{backgroundColor: quantities.includes("1/2") ? '#b8dafd' : '#f8f8f8'}}>1/2</Dropdown.Item>
                <Dropdown.Item eventKey="Full" style={{backgroundColor: quantities.includes("Full") ? '#b8dafd' : '#f8f8f8'}}>Full</Dropdown.Item>
                <Dropdown.Item eventKey="Each" style={{backgroundColor: quantities.includes("Each") ? '#b8dafd' : '#f8f8f8'}}>Each</Dropdown.Item>
                <Dropdown.Item eventKey="2" style={{backgroundColor: quantities.includes("2") ? '#b8dafd' : '#f8f8f8'}}>2</Dropdown.Item>
                <Dropdown.Item eventKey="Zip" style={{backgroundColor: quantities.includes("Zip") ? '#b8dafd' : '#f8f8f8'}}>Zip</Dropdown.Item>
            </Dropdown>
            {quantities.map((quantity, index) => {
    const priceItem = prices.find(item => item.quantity === quantity);
    const priceValue = priceItem ? priceItem.price : '';
    return (
        <Form.Group controlId={`selectedQuantity-${quantity}`} key={`key-${quantity}`}>
            <Form.Label>{quantity} price: </Form.Label>
            <Form.Control type="text" placeholder="Price" value={priceValue} required
                onChange={e => handlePriceChange(e.target.value, quantity)}/>
        </Form.Group>
    );
})}

        </Form.Group>
        {
          category === 'solventless' 
          ? <Form.Group controlId="brand">
              <Form.Label>Brand:</Form.Label>
              <Form.Control value={brand} type="text" onChange={e => setBrand(e.target.value)} required />
            </Form.Group>
          : <><Form.Group controlId="image">
              <Form.Label>Image:</Form.Label>
              <Form.Control type="file" onChange={handleImageChange} required={!productToEdit} />
            </Form.Group>
            <Form.Group controlId="brand">
            <Form.Label>Brand:</Form.Label>
            <Form.Control value={brand} type="text" onChange={e => setBrand(e.target.value)}  />
          </Form.Group></>
        }
       
          <Form.Group controlId='type'>
            <Form.Label>Type:</Form.Label>
            <Form.Control type="text" value={type} onChange={e => setType(e.target.value)} required />
          </Form.Group>
        
        <Form.Group controlId="description">
            <Form.Label>Description:</Form.Label>
            <Form.Control as="textarea" value={description} onChange={e => setDescription(e.target.value)} required />
        </Form.Group>
        <Form.Check type="switch" id="onSale-Switch" label="On Sale" checked={onSale} onChange={e => setOnSale(e.target.checked)} />
        <Form.Check type="switch" id="inStock-Switch" label="In Stock" checked={inStock} onChange={e => setInStock(e.target.checked)} />
        <Form.Check 
        type="switch" 
        id="reno-Switch" 
        label="Reno" 
        checked={reno} 
        onChange={e => setReno(e.target.checked)} 
      />
        {/* <Button variant="primary" type="button" onClick={ () => console.log({ strain, category, quantities, prices, description, image, onSale, inStock, brand }) }>Test Values</Button> */}
        <center>
            {loading
                ? <ThreeDots color="#00BFFF" height={15} width={80} /> // Display the loading spinner if loading is true
                : <Button type='submit'>{productToEdit ? 'Update Product': 'Add Product'}</Button> // Display the submit button if loading is false
            }
        </center>
    </Form>
  )
}

export default ProductForm