import React from 'react';
import './Footer.css';
// import logo from './logo5.png';

const Footer = () => {
  const handleNavClick = (href) => {
    return (event) => {
      event.preventDefault();
      const targetElement = document.querySelector(href);
      const offset = 100; // Adjust this value to set how much offset you want
      const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    };
  };

  return (
    <footer className="footer">
      <div className="footer-section">
        <h3>The Arbor Connection</h3>
        <img src="/Images/arborwhite6.png" alt="Logo" width="130" height="100" />
      </div>
      <div className="footer-section">
        <h3>Quick Links</h3>
        <ul className="footer-links">
          {[
            { name: 'Flower', href: '#flower' },
            { name: 'Solventless', href: '#concentrates' },
            { name: 'Concentrates', href: '#concentrates' },
            { name: 'Cartridges', href: '#cartridges' },
            { name: 'Edibles', href: '#edibles' },
            { name: 'Prerolls', href: '#prerolls' },
          ].map((item) => (
            <li key={item.name}>
              <a href={item.href} onClick={handleNavClick(item.href)}>
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="footer-section">
        <h3>Hours of Operation</h3>
        <p>Mon: 2:00pm - 10:00pm</p>
        <p>Tue: 2:00pm - 10:00pm</p>
        <p>Wed: 2:00pm - 10:00pm</p>
        <p>Thu: 2:00pm - 10:00pm</p>
        <p>Fri: 2:00pm - 10:00pm</p>
        <p>Sat: 12:00pm - 10:00pm</p>
        <p>Sun: 12:00pm - 10:00pm</p>
      </div>
    </footer>
  );
};

export default Footer;