import React from 'react';
import './Home.css'; // Assuming you have a CSS file for styling
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";

const Home = () => {
  const handleButtonClick = (href) => {
    return (event) => {
      event.preventDefault();
      const targetElement = document.querySelector(href);
      const offset = 100; // Adjust this value to set how much offset you want
      const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition ;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div>
      <div className="home">
        <div className="centered-content">
          <h1 className="title"><span>The</span> <span>Arbor</span> <span>Connection</span></h1>
          <button className="image-button" onClick={handleButtonClick("#flower")}></button>
        </div> 
      </div>
      <center style={{height:0}}>
        <button className='view-butt' onClick={handleButtonClick("#flower")}><MdOutlineKeyboardDoubleArrowDown color='#eb2572' /></button>
      </center>
    </div>
  );
}

export default Home;