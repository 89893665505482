import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';

const DragNDrop = ({ deleteProduct, items, allItems, setItems, setOrder, handleOnDragEnd, dropId, category, editProduct }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" , marginTop:100}}>
      <div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId={dropId}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items && items.map((item, index) => (
                  <Draggable key={item.productId} draggableId={item.productId} index={index}>
                    {(provided) => (
                      <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                        <div key={index} style={{ display: "flex", width: '500px', marginBottom: '10px', borderRadius: '20px', boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)", alignItems: "center" }}>
                          <img src={item.category === 'solventless' ? "/Images/logo.png" : item.imageurl} alt={item.strain} style={{ margin: 10, height: '50px', width: '50px' }} />
                          <div style={{ margin: 10 }}>{item.strain}</div>
                          <div style={{ margin: 10, marginLeft: 'auto' }}>{item.category}</div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button onClick={() => editProduct(item)} style={{ margin: 10, height: 40 }}>Edit</Button>
                            <Button onClick={() => deleteProduct(item.productId)} style={{ margin: 10, height: 40 }}><FiX /></Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default DragNDrop;